import React from "react";
import "./Reasons.css";
//? imagis here
import img1 from "../../assets/image1.png";
import img2 from "../../assets/image2.png";
import img3 from "../../assets/image3.png";
import img4 from "../../assets/image4.png";
import nb from "../../assets/nb.png";
import adidas from "../../assets/adidas.png";
import nike from "../../assets/nike.png";
import tick from "../../assets/tick.png";

const Reasons = () => {
    return (
        <div className="container reasons flex-box">
            <div className="left-s">
                <div className="left_s_img">
                    <img src={img1} alt="" />
                </div>
                <div className="right_s_img">
                    <div>
                        <img src={img2} alt="" />
                    </div>
                    <div className="flex-box  img_bottum">
                        <img src={img3} alt="" />
                        <img src={img4} alt="" />
                    </div>
                </div>
            </div>
            <div className="right-s">
                <span className="heading-sm-text">Some Reasons</span>
                <h3>
                    <span className="text-trock">WHY </span> CHOOSE US?
                </h3>
                <ul>
                    <li>
                        <img src={tick} alt="tick" />
                        over 140+ EXPERT COACHS{" "}
                    </li>
                    <li>
                        <img src={tick} alt="tick" />
                        train smater and faster then before{" "}
                    </li>
                    <li>
                        <img src={tick} alt="tick" />1 free program from new member{" "}
                    </li>
                    <li>
                        <img src={tick} alt="tick" />
                        reliable partners{" "}
                    </li>
                </ul>
                <span>our partners</span>
                <div className="logos flex-box item-center">
                    <img src={nb} alt="" />
                    <img src={adidas} alt="" />
                    <img src={nike} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Reasons;
