import { Routes,Route } from 'react-router-dom';
import './App.css';
import Hero from './components/Hero/Hero';
import Home from './pages/Home/Home';
import Programs from './pages/Programs/Programs';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
function App() {
  return (
   
    <div className="App">
        <Header />
          <Routes>
            <Route index path="/" element={<Hero />} />
            <Route path='/home' element={<Home />} />
            <Route  path='/programs' element={<Programs />} />
          </Routes>
        <Footer />  
    </div>
  );
}

export default App;
