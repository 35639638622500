import React from "react";
import "./Plan.css";
import whitetick from "../../assets/whiteTick.png";
import {plansData} from "../../data/plansData"
import { Link } from "react-router-dom";

import blur from "../../assets/Rectangle 42.svg"

const Plan = () => {
    return (
        <div className="container plan_container">
            <div className="blur plan_blur1"><img src={blur} alt="" /></div>
            <div className="blur plan_blur2"><img src={blur} alt="" /></div>
            <div className="program_headers flex-box space-between">
                <h3 className="text-trock">ready to start</h3>
                <h3>your journey</h3>
                <h3 className="text-trock">now withus</h3>
            </div>
            <div className="plan flex-box space-between">
                {plansData.map((plan, i) => {
                    return (
                        <div key={i} className="features_main flex-box">
                            {plan.icon}
                            <span>{plan.name}</span>
                            <span>${plan.price}</span>
                            <div className="features">
                                {plan.features.map((features,i) => {
                                    return(
                                        <div key={i} className="featurs flex-box item-center">
                                            <img src={whitetick} alt="" />
                                            <span key={i}>{features}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            <div style={{marginBottom:"20px"}}><span>see more benefits -&gt; </span></div>
                            <Link to="#" className="btn plan_btn" >join now</Link>
                        </div>
                    );
                })}
                </div>
        </div>
    );
};

export default Plan;
