import React from "react";
import "./Programs.css";
import { programsData } from "../../data/programsData";
import { Link } from "react-router-dom";
import rightarro from "../../assets/rightArrow.png"

const Programs = () => {
    return (
        <div className="programs" id="programs">
            <div className="container    programs_header flex-box">
                <span className="text-trock">EXPLORE OUR</span>
                <span>PROGRAMS</span>
                <span className="text-trock">TO SHAPE YOU</span>
            </div>
            <div className="program_cetogeri flex-box space-between">
                {programsData.map((program,i) => {
                    return (
                        <div key={i} className="cetegori flex-box space-between">
                            {program.image}
                            <span>{program.heading}</span>
                            <span>{program.details}</span>
                            <Link to="#" className="flex-box  item-center">join now <img src={rightarro} alt="" /></Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Programs;
