import React, { useState } from "react";
import "./Testimonials.css";
import { testimonialsData } from "../../data/testimonialsData";
import { motion } from "framer-motion"
// ? img imported
import leftarrow from "../../assets/leftArrow.png"
import rightarrow from "../../assets/rightArrow.png"


const Testimonial = () => {
    const [slected, setSlected] = useState(0);
    const tlength = testimonialsData.length;
    const transition = { type: 'spring', duration: 3 }
    return (
        <div className="testimonials container flex-box">
            <div className="left_testi">
                <span className="sm_ttl">Testimonial</span>
                <h3 className="text-trock">What They</h3>
                <h3>say about us</h3>
                <motion.span
                    key={slected}
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 100 }}
                    transition={transition}
                    className="review">{testimonialsData[slected].review}</motion.span>
                <div style={{ color: "white", fontWeight: "500" }}>
                    <span style={{ color: "var(--orange)" }}>{testimonialsData[slected].name}</span> {""}
                    - {testimonialsData[slected].status}

                </div>
            </div>
            <div className="right_testi">
                <div className="right_img">
                    <motion.div
                        initial={{ opacity: 0, x: -100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ ...transition, duration: 2 }}
                        className="img_border"></motion.div>
                    <motion.div
                        initial={{ opacity: 0, x: 100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ ...transition, duration: 2 }}
                        className="img_bg"></motion.div>
                    <motion.img
                        key={slected}
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -100 }}
                        transition={transition}
                        src={testimonialsData[slected].image} alt="" />
                </div>
                <div className="arrows flex-box">
                    <img onClick={() => {
                        slected === 0 ? setSlected(tlength - 1) : setSlected((prew) => prew - 1)
                    }}
                        src={leftarrow} alt="" />
                    <img onClick={() => {
                        slected === tlength - 1 ? setSlected(0) : setSlected((next) => next + 1)
                    }}
                        src={rightarrow} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Testimonial;
