import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import $ from 'jquery'
// import "jquery-ui-dist/jquery-ui'"




//? logo import
import logo from "../../assets/logo.png";

const Header = () => {

    useEffect(() => {
        $(document).ready(function () {
            var scroll = $(window).scrollTop();
            if (scroll >= 50) {
                $('.header').addClass('fixed');
            }
            else {
                $('.header').removeClass('fixed');
            }
            $(window).scroll(function () {
                var scroll = $(window).scrollTop();
                if (scroll >= 50) {
                    $('.header').addClass('fixed');
                }
                else {
                    $('.header').removeClass('fixed');
                }
            });

            $(".menu-btn").click(function () {
                $(".menu-btn").toggleClass("active");
                $("body").toggleClass("locked");
                $(".header .header-right").slideToggle();
            });

            var resizeTimer;
            $(window).on('resize', function (e) {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(function () {
                    $(".menu-btn").removeClass("active");
                    $("body").removeClass("locked");
                    $(".header .header-right").removeAttr('style');
                }, 250);
            });

            $(".back-top").click(function () {
                $("html, body").animate({ scrollTop: 0 }, 1000);
            });
        });
    }, [])

    return (
        <header className=" header" id="header">
            <div className="container flex-box space-between item-center">
                <Link to="/" className="logo">
                    <img src={logo} alt="" />
                </Link>
                <div className="menu-btn">
                        <span className="menu-line"></span>
                </div>
                <div className="header-right">
                    <nav className="flex-box item-center ">
                        <ul className="flex-box">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="#">Programs</Link>
                            </li>
                            <li>
                                <Link to="#">Why us</Link>
                            </li>
                            <li>
                                <Link to="#">Plan</Link>
                            </li>
                            <li>
                                <Link to="#">Testimonials</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Link to="#" className="btn join_btn">join now</Link>
            </div>
        </header>
    );
};

export default Header;
