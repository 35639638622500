import React from "react";
import "./Hero.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion"
import NumberCounter from "number-counter"

//? imagis here
import hero_image from "../../assets/hero_image.png"
import hero_image_back from "../../assets/hero_image_back.png"
import heart from "../../assets/heart.png"
import calories from "../../assets/calories.png"
import blur from "../../assets/Rectangle 42.svg"

//? components import here
import Programs from "../Programs/Programs";
import Reasons from "../Reasons/Reasons";
import Plan from "../Plans/Plan";
import Testimonial from "../Testimonials/Testimonial";
import Join from "../Join/Join";

const Hero = () => {
    const transition = { type: 'spring', duration: 3 }



    return (
        <>
            <div className="hero container flex-box space-between ">
                <div className="blur hero_blur"><img src={blur} alt="" /></div>
                <div className="hero-left">
                    <div className="hero-left-content flex-box item-center">
                        <motion.div
                            initial={{ left: '250px' }}
                            whileInView={{ left: '8px' }}
                            transition={{ ...transition, type: 'tween' }}
                        >
                        </motion.div>
                        <span>the best fitness club in the rajkot</span>
                    </div>
                    <div className="banner-ttl">
                        <h2><span className="text-trock">Shape</span> <span>Your</span></h2>
                        <h2>Idel Body</h2>
                    </div>
                    <div className="banner-sub-ttl">
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Esse tenetur non fugit perferendis ullam iste, atque praesentium iure adipisci fugiat veritatis tempora earum, consectetur mollitia quaerat cumque suscipit repellat eos!</p>
                    </div>
                    <div className="figurs flex-box">
                        <div><span><NumberCounter start={0} end={14} delay={.5} preFix='+' /></span><span>EXPERT COACHS</span></div>
                        <div><span><NumberCounter start={0} end={487} delay={0.210} preFix='+' /></span><span>MEMBERS JOINED</span></div>
                        <div><span><NumberCounter start={0} end={50} delay={.2} preFix='+' /></span><span>FITNESS PROGRAMS</span></div>
                    </div>
                    <div className="banner-button">
                        <Link className="btn" to="/get-started">Get Started</Link>
                        <Link className="btn" to="/learn-more">Learn More</Link>
                    </div>
                </div>
                <div className="hero-right">
                    <motion.div
                        initial={{ left: -10 }}
                        whileInView={{ left: 100 }}
                        transition={transition}
                        className="heart-rate flex-box item-center space-between">
                        <img src={heart} alt="" />
                        <span>Heart Rate</span>
                        <span>116 bpm</span>
                    </motion.div>
                    <img src={hero_image} className="hero_img" alt="" />
                    <motion.img
                        initial={{ right: 10 }}
                        whileInView={{ left: "-13.75rem" }}
                        transition={transition}
                        src={hero_image_back} className="hero_image_back" alt="" />
                    <motion.div
                        initial={{ right: -10 }}
                        whileInView={{ left: -300 }}
                        transition={transition}
                        className="celoris flex-box item-center space-between">
                        <img src={calories} alt="" />
                        <div>
                            <span>calories Burned</span>
                            <span>220 kcal</span>
                        </div>
                    </motion.div>
                </div>
            </div>
            <Programs />
            <Reasons />
            <Plan />
            <Testimonial />
            <Join />
        </>
    );
};

export default Hero;
