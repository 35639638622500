import React from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom'

//? import images
import Github from "../../assets/github.png"
import Instagram from "../../assets/instagram.png"
import Linkedin from "../../assets/linkedin.png"
import Logo from "../../assets/logo.png"
import blur from "../../assets/Rectangle 42.svg"
import redblur from "../../assets/red-blur.svg"

const Footer = () => {
    return (
        <footer className='footer '>
            <div className='container footer_container'>
                <div className='flex-box item-center footer_links'>
                    <Link to="https://github.com/" target='_blank' ><img src={Github} alt="" /></Link>
                    <Link to="https://www.instagram.com/" target='_blank'><img src={Instagram} alt="" /></Link>
                    <Link to="https://linkedin.com/in/rahul-solanki-444b10296/" target='_blank'><img src={Linkedin} alt="" /></Link>
                </div>
                <div className="f_logo">
                    <Link to="#"><img src={Logo} alt="" /></Link>
                </div>
        <div className="blur f_blur-1"><img src={blur} alt="" /></div>
        <div className="blur f_blur-2"><img src={redblur} alt="" /></div>
            </div>
        </footer>
    )
}

export default Footer
