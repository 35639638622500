import React from 'react'
import "./Home.css"

const Home = () => {
  return (
    <div>
      <h1>home</h1>
    </div>
  )
}

export default Home
