import React, { useRef } from "react";
import "./Join.css";
import emailjs from "@emailjs/browser";

const Join = () => {

    //! EMAIL VALIDATION ON EMAILJS
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_hzphip8", "template_khuv6n8", form.current, "RftQeSZ7QlRN19jGh",)
            .then(
                () => {
                    console.log("SUCCESS!")
                }, (error) => {
                    console.log("FAILED...", error.text())
                }
            )
    }


    return (
        <div className=" container flex-box join_section">
            <div className="join_left">
                <h3>
                    <span className="text-trock">ready to </span> level up
                </h3>
                <h3>
                    your body <span className="text-trock"> with us? </span>
                </h3>
            </div>
            <div className="join_right">
                <form ref={form} action="post flex-box" onSubmit={sendEmail}>
                    <input
                        type="email"
                        name="user_email"
                        placeholder="enter you email addres here"
                        required
                    />
                    <button required type="submit" className="btn form_btn">
                        join now
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Join;
